<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.87353 7.09424C2.32125 7.09424 1.87353 7.54195 1.87353 8.09424C1.87353 8.64652 2.32125 9.09424 2.87353 9.09424L6.8656 9.09424L6.8656 13.0862C6.8656 13.6385 7.31332 14.0862 7.8656 14.0862C8.41789 14.0862 8.8656 13.6385 8.8656 13.0862L8.8656 9.09424L12.8575 9.09424C13.4097 9.09424 13.8575 8.64652 13.8575 8.09424C13.8575 7.54195 13.4097 7.09424 12.8575 7.09424L8.8656 7.09424L8.8656 3.10226C8.8656 2.54998 8.41788 2.10226 7.8656 2.10226C7.31331 2.10226 6.8656 2.54998 6.8656 3.10226L6.8656 7.09424L2.87353 7.09424Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPlus'
}
</script>
